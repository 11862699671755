'use strict';

import {IDocumentService, ILogService, IScope} from "angular";
import MapService from "../../../../services/map.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";
import * as L from 'leaflet';
import {RolePrivilege} from "../../../../data/privileges.enum";
import {EMapTripDirection, EMapTripType, ERoadblockType, Roadblock} from "../../../../data/roadblock.data";
import angular = require("angular");

require('./edit.roadblock.modal.css');

/* @ngInject */
export default class EditRoadblockController {
  public types: any;
  public mapTripTypes: any;
  public mapTripDirections: any;
  public settings: any;
  public isReadOnly = false;
  public hasExternalSource = false;
  public isShiftBookActive = false;
  public isMapTripActive = false;
  public isGeoCoding = false;
  public isLoading = false;
  public roadblockToReset: any;
  public map: any;
  public from: any;
  public form: any;
  public to: any;
  private maptripMarkers: L.FeatureGroup = new L.FeatureGroup();
  private okFunction;

  constructor(public $uibModalInstance,
    public $document: IDocumentService,
    public $scope: IScope,
    public $uibModal,
    public $log: ILogService,
    public dataService,
    public mapService: MapService,
    public roadblock: Roadblock,
    public accountService,
    public restService: RestService,
    public privilegeService: PrivilegeService, okFunction) {

    this.types = this.dataService.getRoadblockTypes();
    this.mapTripTypes = this.dataService.getRoadblockMapTripTypes();
    this.mapTripDirections = this.dataService.getRoadblockMapTripDirections();
    this.roadblock = roadblock;
    this.settings = this.dataService.getAccount().settings;

    this.isReadOnly = this.roadblock.parent !== this.dataService.getAccount().username &&
      this.roadblock.roadblockAccess === 'PUBLIC_READ';
    if (!this.privilegeService.has(RolePrivilege.Objects_Roadblocks_Edit)) {
      this.isReadOnly = true;
    }
    this.hasExternalSource = this.roadblock.source !== undefined;

    this.isShiftBookActive = accountService.isFeatureEnabled('SHIFT_BOOK');
    this.isMapTripActive = accountService.isFeatureEnabled('MAP_TRIP');
    this.isGeoCoding = false;
    this.okFunction = okFunction;

    this.initLocation(roadblock);


    //Register for roadblock events
    this.updateDates(roadblock);

    //deep copy inital roadblock for cancelling modification
    this.roadblockToReset = angular.copy(this.roadblock);

    $document.ready(() => {
      this.validate();
      L.Icon.Default.imagePath = '/img/static';
      this.map = L.map('mapid').setView([this.settings.lat, this.settings.lng], 13);
      var currentRouteShape: L.Polyline = undefined;
      var currentMarker: L.Marker = undefined;

      this.initLayers();

      // FeatureGroup is to store editable layers
      var drawnItems = new L.FeatureGroup();
      this.map.addLayer(drawnItems);
      this.map.addLayer(this.maptripMarkers);
      var drawControl = new L.Control.Draw({
        draw: {
          polyline: {
            tooltip: {
              start: 'Klicken um eine Linie zu zeichnen',
              cont: 'Klicken um die Linie weiter zu zeichnen',
              end: 'Auf den letzten Punkt klicken um die Zeichnung zu beenden.'
            }
          },
          circle: false,
          rectangle: false,
          polygon: false,
          marker: true,
          circlemarker: false
        },
        edit: {
          featureGroup: drawnItems
        }
      });
      if (!this.isReadOnly) {
        this.map.addControl(drawControl);
      }

      // Map events
      // Line is created
      this.map.on('draw:created', (layer) => {
        if (layer.layerType === 'marker') {
          var geoJson = layer.layer.toGeoJSON();
          drawnItems.addLayer(layer.layer);
          var coor = geoJson.geometry.coordinates;
          this.roadblock.routeShape = [
            [coor[1], coor[0]]
          ];
          this.$scope.$applyAsync();
        } else {
          var geoJson = layer.layer.toGeoJSON();
          this.roadblock.routeShape = [];
          for (var i = 0; i < geoJson.geometry.coordinates.length; i++) {
            var coor = geoJson.geometry.coordinates[i];
            this.roadblock.routeShape.push([coor[1], coor[0]]);
          }

          if (this.isMapTripActive) {
            this.restService.getRoadblockDetourMatching(this.roadblock, (response) => {
              this.roadblock.routeShape = response;
              this.drawRoadblock(currentMarker, currentRouteShape, drawnItems);
              this.$scope.$applyAsync();
            }, (errorResponse) => {
              this.$log.error('Fehler beim Berechnen der MapTrip Strecke', errorResponse);
              drawnItems.addLayer(layer.layer);
              this.$scope.$applyAsync();
            });
          } else {
            drawnItems.addLayer(layer.layer);
            this.$scope.$applyAsync();
          }
        }
      });

      // User starts drawing
      this.map.on('draw:drawstart ', () => {
        drawnItems.clearLayers();
        // Remove current route shape
        if (currentRouteShape !== undefined) {
          drawnItems.removeLayer(currentRouteShape);
          currentRouteShape = undefined;
        }
        if (currentMarker !== undefined) {
          drawnItems.removeLayer(currentMarker);
          currentMarker = undefined;
        }
      });

      // User edits current shape
      this.map.on('draw:edited ', (e) => {
        var layers = e.layers;
        layers.eachLayer((layer) => {
          var geoJson = layer.toGeoJSON();
          this.roadblock.routeShape = [];
          for (var i = 0; i < geoJson.geometry.coordinates.length; i++) {
            var coor = geoJson.geometry.coordinates[i];
            this.roadblock.routeShape.push([coor[1], coor[0]]);
          }
          this.drawRoadblock(currentMarker, currentRouteShape, drawnItems);
          this.$scope.$applyAsync();
        });

      });
      // User deletes shape
      this.map.on('draw:deleted', () => {
        this.roadblock.routeShape = [];
        this.maptripMarkers.clearLayers();
        this.$scope.$applyAsync();
      });

      this.drawRoadblock(currentMarker, currentRouteShape, drawnItems);

      this.map.invalidateSize();
    });

  }
  calculateAddress() {
    this.geocode(this.createLocation());
  }

  drawRoadblock(currentMarker: L.Marker, currentRouteShape: L.Polyline, drawnItems: L.FeatureGroup) {
    // Draw polyline if rout shape exists

    if (this.roadblock.routeShape.length === 1) {
      // Only a single marker
      var point = this.roadblock.routeShape[0];
      var p = new L.LatLng(point[0], point[1]);
      currentMarker = L.marker(p).addTo(this.map);
      drawnItems.addLayer(currentMarker);
      this.map.setView(p, 13);
    } else {
      // Polyline
      var pointList = [];
      for (var i = 0; i < this.roadblock.routeShape.length; i++) {
        var point = this.roadblock.routeShape[i];
        var p = new L.LatLng(point[0], point[1]);
        pointList.push(p);
      }
      if (pointList.length > 0) {
        var color = 'red';
        currentRouteShape = new L.polyline(pointList, {
          color: color,
          weight: 4,
          opacity: 1,
          smoothFactor: 1
        });
        drawnItems.addLayer(currentRouteShape);
        this.setMapTripMarkers();
        this.map.fitBounds(L.latLngBounds(pointList), {maxZoom: 15});
      }
    }
  }

  changeMaptripDirection(direction: EMapTripDirection) {
    this.roadblock.mapTripDirection = direction;
    this.setMapTripMarkers();
  }

  changeType(type: ERoadblockType) {
    this.roadblock.type = type;
    switch (this.roadblock.type) {
      case 'ROAD_WORKS':
        this.roadblock.mapTripType = EMapTripType.AVOID;
        break;
      case 'ROAD_CLOSED':
        this.roadblock.mapTripType = EMapTripType.BLOCK;
        break;
      case 'INCIDENT':
        this.roadblock.mapTripType = EMapTripType.AVOID;
        break;
    }
    this.setMapTripMarkers();
  }

  setMapTripMarkers() {
    var iconUrl = 'img/static/';
    switch (this.roadblock.type) {
      case 'ROAD_WORKS':
        iconUrl = iconUrl + 'roadwork.png';
        break;
      case 'ROAD_CLOSED':
        iconUrl = iconUrl + 'roadclosed.png';
        break;
      case 'INCIDENT':
        iconUrl = iconUrl + 'roadwarn.png';
        break;
    }

    var icon = L.icon({
      iconUrl: iconUrl,
      iconSize: [24, 24],
      iconAnchor: [12, 12]
    });

    this.maptripMarkers.clearLayers();

    if (this.roadblock.mapTripDirection !== EMapTripDirection.BTOA) {
      var marker = L.marker(this.roadblock.routeShape[0], {icon: icon});
      this.maptripMarkers.addLayer(marker);
    }
    if (this.roadblock.mapTripDirection !== EMapTripDirection.ATOB && this.roadblock.routeShape.length > 1) {
      var marker = L.marker(this.roadblock.routeShape[this.roadblock.routeShape.length-1], {icon: icon});
      this.maptripMarkers.addLayer(marker);
    }
  }

  initLayers() {
    this.map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');

    let layers = this.mapService.getBaseLayers();

    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    let mapService = this.mapService
    this.map.on('baselayerchange', function (e) {
      mapService.saveLayer(e.name);
    });

  }

  createLocation() {
    let location = '';

    if (this.roadblock.street) {
      location = this.roadblock.street;
    }

    if (this.roadblock.postalCode) {
      location = location + ' ' + this.roadblock.postalCode;
    }
    if (this.roadblock.city) {
      location = location + ' ' + this.roadblock.city;
    }

    location = location.trim();
    return location;
  }
  geocode(location: string) {
    this.$log.debug(`Geocoding Location: ${location}`);
    if (location === '' || location.length < 5) {
      return;
    }
    this.isGeoCoding = true;
    this.restService.geocode(location, (response) => {
      this.isGeoCoding = false;
      this.$log.debug(response.data);
      this.recenterMap(response.data);
    }, (error) => {
      this.isGeoCoding = false;
      this.$log.error(error);
    });
  }

  recenterMap(data) {
    if (angular.isDefined(data)) {
      this.map.setView([data.latitude, data.longitude], 16);
    }

  }


  validate(): boolean {
    var hasFrom = false;
    var hasTo = false;
    if (this.from === undefined || this.from === null) {
      this.form.from.$setValidity('from', false);
    } else {
      hasFrom = true;
    }

    if (this.to === undefined || this.to === null) {
      this.form.to.$setValidity('to', false);
    } else {
      hasTo = true;
    }

    if (hasFrom && hasTo) {
      this.form.from.$setValidity('from', true);
      this.form.to.$setValidity('to', true);
      if (this.from.getTime() > this.to.getTime()) {
        this.form.to.$setValidity('to', false);
      }
    }

    var hasName = this.roadblock.name !== undefined && this.roadblock.name !== null && this.roadblock.name.length > 0;
    this.form.name.$setValidity('name', hasName);

    return hasFrom && hasTo && hasName;
  };

  /**
   * Save object
   */
  save(sendNotification: boolean) {
    this.isLoading = true;
    this.roadblock.from = this.from;
    this.roadblock.to = this.to;
    this.dataService.updateRoadblock(this.roadblock, sendNotification, (roadblock) => {
      this.updateDates(roadblock);
      this.roadblock = roadblock;
      //reset deep copy to prevent setting old roadblock after saving and cancelling
      this.roadblockToReset = angular.copy(this.roadblock);
      this.isLoading = false;
      this.$uibModalInstance.close();
      this.okFunction();
    }, (errorResponse) => {
      //Error occured
      this.$log.error(errorResponse);
      this.isLoading = false;
    });
  };

  updateDates(roadblock: Roadblock) {
    if (roadblock.to) {
      this.to = new Date(Date.parse(roadblock.to));
      //avoid displaying (milli)seconds
      this.to.setSeconds(0);
      this.to.setMilliseconds(0);
    }
    if (roadblock.from) {

      this.from = new Date(Date.parse(roadblock.from));
      this.from.setSeconds(0);
      this.from.setMilliseconds(0);
    }
  }


  /**
   * Delete object
   */
  delete() {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.dataService.deleteRoadblockById(this.roadblock, () => {
              this.isLoading = false;
              this.$uibModalInstance.close();
            }, (response) => {
              //Error occured
              this.isLoading = false;
              this.$log.error(response);
            });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  };

  cancel() {
    //reset roadblock in list
    this.dataService.getAllRoadblocks(true, (response) => { }, (errorResponse) => {
      this.$log.error('error getting all roadblocks for cancelling');
      this.$log.error(errorResponse);
    });

    this.$uibModalInstance.close();
  }

  initLocation(roadblock) {
    if (angular.isDefined(roadblock.city)) {
      this.roadblock.city = roadblock.city;
    } else {
      this.roadblock.city = this.settings.city;
    }

    if (angular.isDefined(roadblock.postalCode)) {
      this.roadblock.postalCode = roadblock.postalCode;
    } else {
      this.roadblock.postalCode = this.settings.postalCode;
    }

  }
}
