import { CustomFeedbackResponseResponse, CreateCustomFeedbackResponseRequest } from './../../../data/customFeedback.data';
'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import angular = require("angular");
import { AddUnitRequest, EUnitType, Unit, UnitSettings } from "../../../data/unit.data";
import UnitsService from "../../../services/units.service";
import PrivilegeService from '../../../services/privilege.service';
import { RolePrivilege } from '../../../data/privileges.enum';
import RestService from '../../../services/rest.service';

require('./units.view.component.css');
/* @ngInject */
export default class UnitsController {
  public $log: ILogService;
  public $location: any;
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $state: any;
  public $uibModal: any;
  public $stateParams: any;
  public dataService: any;
  public unitsService: UnitsService;
  public listeners: any;
  public account: any;
  public hasRightToAlert: boolean = false;
  public isLoading: boolean = false;
  public isLoadingUnits: boolean = false;
  public units: Unit[] = [];
  public unit: Unit;
  public isSavingUnitSettings: boolean = false;
  public activeTabIndex: number = 0;

  public customFeedbacks: Array<CustomFeedbackResponseResponse> = [];

  constructor($rootScope: IRootScopeService, $scope: IScope, $log: ILogService, $state, $location, $uibModal, $stateParams, dataService, unitsService: UnitsService,
    public privilegeService: PrivilegeService,
    public restService: RestService) {
    this.$log = $log;
    this.$location = $location;
    this.$log.debug('UnitsController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$stateParams = $stateParams;
    this.dataService = dataService;
    this.unitsService = unitsService;

    this.listeners = [];
    this.initListeners();

    if (this.dataService.hasAccount()) {
      this.init();
    }
  }

  init() {

    if (!this.privilegeService.has(RolePrivilege.Units)) {
      this.$state.go('main.' + this.dataService.selectPageForPrivilege());
      return;
    }


    this.account = this.dataService.getAccount();
    this.loadUnits();
    this.updateCustomFeedbackList();
  };

  /**
   * Load units from server
   */
  loadUnits() {
    if (!this.privilegeService.has(RolePrivilege.Units)) {
      return;
    }
    this.isLoadingUnits = true;
    this.restService.loadUnitsForUnitsView().then((units) => {
      this.units = units[this.account.username];
      this.$scope.$emit('units.updated', this.units);
      this.isLoadingUnits = false;
      //Add unit from query param
      if (angular.isDefined(this.$stateParams.code)) {
        const code = this.$stateParams.code;
        this.$location.search('code', null);// Overwrite query parameter
        for (var i = 0; i < this.units.length; i++) {
          if (this.units[i].code === code) {
            this.loadPipeline(this.units[i])
            break;
          }
        }
      }
    }).finally(()=>{
      this.$scope.$applyAsync();
    });
  }

  /*
    Load the pipeline from server
  */
  loadPipeline(unit: Unit) {

    this.isLoading = true;
    this.unit = unit;

    this.restService.loadPipeline(unit.id, (pipeline) => {
      this.isLoading = false;

      this.$uibModal.open({
        template: require('../../modals/pipeline/edit.unit.modal/edit.unit.modal.html'),
        controller: 'EditUnitModalController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: this.privilegeService.has(RolePrivilege.Units_Edit) ? 'hu' : 'lg',
        resolve: {
          unit: () => {
            return this.unit;
          },
          pipeline: () => {
            return pipeline;
          }
        }
      })

    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  }

  /**
   * Update unit settings
   */
  updateSettings(unit: Unit) {
    this.$log.debug(unit.settings);
    this.isSavingUnitSettings = true;

    if (!unit.settings.hasCustomFeedback) {
      unit.settings.customFeedbackId = undefined;
    }

    this.restService.saveUnitSettings(unit.id, unit.settings, (data: UnitSettings) => {
      this.$log.debug(data);
      unit.settings = data;
      this.isSavingUnitSettings = false;

      this.$scope.$emit('units.updated', this.units);
    },
      (response) => {
        //Error occured
        this.$log.error(response);
        this.isSavingUnitSettings = false;
      });

  }


  /**
    Alert the modified unit
  */
  alertUnit(unit: Unit) {
    this.$location.search('code', unit.code);
    this.$state.go('main.alarm', {
      code: unit.code
    });
    this.$rootScope.$emit('tab.select.alarm');
  };

  /**
   * Init event listeners
   */
  initListeners() {
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', () => {
      //Init controller
      this.init();
    }));

    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('delete.account', () => {
      this.account = undefined;
    }));

    //Update custom feedbacks
    this.listeners.push(this.$rootScope.$on('update.custom.feedback', () => {
      this.updateCustomFeedbackList();
    }));

    // Update Unit list after unit was saved
    this.listeners.push(this.$rootScope.$on('unit.pipeline.saved', () => {
      this.loadUnits();
    }))

    this.listeners.push(this.$rootScope.$on('tab.change.unit', (event, data) => {
      // If else to check if a valid tab index is received
      if (data === 0) {
        this.activeTabIndex = 0;
      } else if (data === 1) {
        this.activeTabIndex = 1;
      }
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Open modal to add a new unit
   */
  addUnit() {
    this.$uibModal.open({
      template: require('../../modals/pipeline/add.unit.modal/add.unit.modal.html'),
      controller: 'AddUnitModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        okFunction: () => {
          return (request: AddUnitRequest) => {
            this.isLoading = true;
            this.restService.addUnit(request).finally(() => {
              this.isLoading = false;
              this.loadUnits();
              this.$scope.$applyAsync();
            });
          }
        }
      }
    });
  };

  /**
   * Delete existing unit
   */
  deleteUnit(unit: Unit) {
    if (!unit.editableByUser) {
      return;
    }
    this.$uibModal.open({
      template: require('../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.restService.deleteUnit(unit.id, () => {
              this.isLoading = false;
              this.loadUnits();
            }, (response) => {
              //Error occured
              this.isLoading = false;
              this.$log.error(response);
            });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  }

  updateCustomFeedbackList() {
    if (!this.privilegeService.has(RolePrivilege.Units_Custom_Feedback)) {
      return;
    }

    this.unitsService.getCustomFeedbacks().then(data => {
      this.customFeedbacks = data;
      this.isLoading = false;
      this.$scope.$apply();
    });
  }

  cfActiveForUnit(unit: Unit, id: string) {
    return unit.settings.customFeedbackId === id;
  }

  updateCustomFeedbackForUnit(unit: Unit, id: string) {
    unit.settings.customFeedbackId = id;
    unit.settings.hasCustomFeedback = true;
    this.updateSettings(unit);
  }


  /**
   * Add a new custom feedback
   */
  addCustomFeedback() {
    this.$uibModal.open({
      template: require('../../modals/customFeedback/edit.custom.feedback.modal/edit.custom.feedback.modal.html'),
      controller: 'EditCustomFeedbackModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        customFeedback: () => {
          return undefined;
        },
        okFunction: () => {
          return () => {
            this.updateCustomFeedbackList();
          };
        },
        closeFunction: () => {
          return () => {
            // Do nothing
          };
        }
      }
    });
  }

  unitSettings(unit: Unit) {
    var modalInstance = this.$uibModal.open({
      template: require('../../modals/pipeline/unit.settings.modal/unit.settings.modal.html'),
      controller: 'UnitSettingsModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        unit: () => {
          return unit;
        },
        user: () => {
          return undefined;//Not needed
        },
        customFeedbackList: () => {
          return this.customFeedbacks;
        }
      }
    });
    modalInstance.result.then(() => this.loadUnits())
  }

  openProtocol(unit: Unit) {
    this.$uibModal.open({
      template: require('../../modals/pipeline/pipeline.protocol.modal/pipeline.protocol.modal.html'),
      controller: 'PipelineProtocolModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        unit: () => {
          return unit;
        }
      }
    })
  }
}
