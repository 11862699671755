import {EServerMode} from "./socket.data";
import {Unit} from "./unit.data";
import {UserSettingsAsAdminRequest} from "./user.data";

export interface ProtocolHistory {
  data: Array<ProtocolEntry>;
  length: number;
  totalElements: number;
  totalPages: number;
  currentPage: number;
}

export interface ProtocolEntry {
  message: string;
  info: string;
  timestamp: number;
  source: EProtocolSource;
  hasAdditionalInformation: boolean;
  id: string;
}

export interface RestProtocolSlice {
  content: RestProtocolSimple[];
  size: number;
  first: boolean,
  last: boolean,
  numberOfElements: number,
  number: number;
}

export interface RestProtocolSimple {
  id: string;
  method: string;
  url: string;
  sourceIP: string;
  responseStatus: number;
  timestamp: number;
  loginName: string;
}

class UserTokenInfo {
  userId: string;
  personId: string;
  loggedInFromAdmin: Boolean;
  tokenExpiration: Date;
  hasWeakPW: Boolean;
  loginName: string;
  userName: string;
  personName: string;
  loginType: string;
  passwordSource: string;
}

export interface RestProtocol {
  id: string;
  method: string;
  url: string;
  sourceIP: string;
  responseStatus: number;
  timestamp: number;

  requestHeaders: Map<string, string>;
  requestParams: Map<string, string>;
  requestBody: string;

  responseHeaders: Map<string, string>;
  responseBody: string;

  timeToLive: Date;

  userInfo: UserTokenInfo;

}

export interface VehicleSwapEntry {
  timestamp: number;
  user: string;
  sourceVehicleId: string;
  sourceVehicleName: string;
  destVehicleId: string;
  destVehicleName: string;
  message: string;
}

export interface VehicleSwapHistory {
    data: Array<VehicleSwapEntry>;
    length: number;
    totalElements: number;
    totalPages: number;
    currentPage: number;
}

export interface ProtocolEntryAdditionalInformation {
    content: string;
}


export interface Relais {
    id: string;
    name: string;
    internalName: string;
    port: number;
    group: ERelaisGroup;
    state: ERelaisState;
    type: ERelaisType;
    impulsTime: number;
    licenced: boolean;
}

export enum ERelaisType {
    STATE = 'STATE',
    IMPULS = 'IMPULS'
}


export enum ERelaisState {
    ON = 'ON',
    OFF = 'OFF'
}

export enum ERelaisGroup {
    SPEAKER = 'SPEAKER',
    LIGHT = 'LIGHT',
    MISC = 'MISC',
    RESERVED = 'RESERVED',
    GATE = 'GATE',
    SECURITY = 'SECURITY'
}

export enum EProtocolSource {
    ALARM = 'ALARM',
    FE2 = 'FE2',
    LOGIN = 'LOGIN',
    LOGIN_FAILED = 'LOGIN_FAILED',
    PIPELINE_EDIT = 'PIPELINE_EDIT',
    USER_ADD = 'USER_ADD',
    USER_DELETE = 'USER_DELETE',
    UNIT= 'UNIT',
    UNIT_ADD = 'UNIT_ADD',
    UNIT_DELETE = 'UNIT_DELETE',
    INPUT = 'INPUT',
    REVISION = 'REVISION',
    EDIT = 'EDIT',
    ROLE = 'ROLE',
    VEHICLES = 'VEHICLES',
    PERSON_SHARE = 'PERSON_SHARE',
    ADDRESSBOOK = 'ADDRESSBOOK',
    OBJECTS = 'OBJECTS',
    ROADBLOCK = 'ROADBLOCK',
    LAYER = 'LAYER',
    TEMPLATE = 'TEMPLATE',
    IOPRINT = 'IOPRINT',
    IOPRINT_ERROR = 'IOPRINT_ERROR',
    EXPORT_PDF = 'EXPORT_PDF',
    RELAIS_ON = 'RELAIS_ON',
    RELAIS_OFF = 'RELAIS_OFF',
    SYNC_OBJECTS = 'SYNC_OBJECTS',
    SYNC_ROADBLOCKS = 'SYNC_ROADBLOCKS',
    MESSAGE = 'MESSAGE',
    MISSION = 'MISSION',
    QUEUE = 'QUEUE'
}

export enum ELogLevel {
    TRACE = 'TRACE',
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR'
}

export interface MapsUsage {
    limit: number,
    used: number,
    remaining: number
}
export interface SmsUsagePerUser {
    user: string;
    months: number[];
}

export interface SmsUsage {
    year: number;
    availableCredits: number;
    users: SmsUsagePerUser[];
}

export interface UserAdminContext {
    name: string;
    organisation: string;
    id: string;
    has2FA: boolean;
    admin: boolean;
    hasOnlineService: boolean;
    onlineServiceName: string;
}

export interface User {
    name: string;
    id: string;
    admin: boolean;
    licenceDetails: LicenceDetailsProUser;
    hasOnlineService: boolean;
    onlineServiceName: string;
    licenseType: string;
    settings: UserSettingsAsAdminRequest;
    allowedIPRange:string;
}

export interface LicenceDetailsProUser {
    nbrOfPersons: number;
    nbrOfAM3s: number;
    nbrOfAMLs: number;
    nbrOfObjects: number;
    nbrOfDangerousGoods: number;
    nbrOfHereMaps: number;
  nbrOfMapTrip: number;
    nbrOfKohlhammer: number;
    nbrOfVehicleCards: number;
    nbrOfLocationMaps: number;
}

export interface UpdateUserRequest {
    name: string;
}

export interface OrganisationLicenceSetAndAvailableData {
    userId: string;
    numbersForLicences: SingleLicenceSetAndAvailable[];
}

export interface SingleLicenceSetAndAvailable {
    usedNbrFE2: number;
    usedNbrOrganisation: number;
    availableInFE2Licence: number;
    setNbrOrganisation: number;
    licenceType: AdjustLicenceType;

}

export enum AdjustLicenceType {
    PERSONS = 'PERSONS',
    AMOBILE = 'AMOBILE',
    AM4 = 'AM4',
    OBJECTS = 'OBJECTS',
    DANGEROUS_GOODS = 'DANGEROUS_GOODS',
    HERE_MAPS = 'HERE_MAPS',
    MAP_TRIP = 'MAP_TRIP',
    KOHLHAMMER = 'KOHLHAMMER',
    VEHICLE_CARDS = 'VEHICLE_CARDS',
    LOCATION_MAPS = 'LOCATION_MAPS'
}

export enum EProvider {
  Google = 'Google',
  Azure = 'Azure',
  User = 'User'
}

export interface UpdateOrganisationLicenseRequest {
    newLicenceDetailsForUser: LicenceDetailsProUser;
}

export interface AdminAddressbookResponse {
    data: DatabaseAdminPersonResponse[],
    totalElements: number,
    totalPages: number,
    currentPage: number;
}

export interface DatabaseAdminPersonResponse {
    displayName: string,
    personID: string,
    user: string,
    note: string
}

export interface DuplicateGlobalSettings {
    defaultTime: number,
    useText: boolean;
    ignoreUpdatedAlarms: boolean
}

export interface CreateOrganisationRequest {
    name: string,
    password: string,
    createOnlineService: boolean;
}


export interface Voice {
    name: string;
    description: string;
    gender: string;
    culture: string;
    version: string;
}

export interface MqttSetting {
    name: string;
}

export interface SmsEagleSignal {
  current: number;
  min: number;
}

export interface ConfigStatusResponse {
  configurationSet: boolean;
}

export interface SmsEagleSettings {
  url: string;
  authKey: string;
  minSignal: number;
}

export interface SmsEagleQueueLength {
  length: number;
}

export interface RestProtocolSettings {
  savingActive: boolean;
  cleaningActive: boolean;
  interval: number;
}

export interface SSOSettings {
  useSingleSignOn: boolean;
  tenant: string;
  apiClientId: string;
  objectId: string;
  clientSecretValue: string;
}

export interface SsoOnResponse {
  useSingleSignOn: boolean;
}

export interface MiscSettings {
    resetTimeStatus5: number;
    resetTimeStatusC: number;
    massAlertTimeInSeconds: number;
    massAlertSeparator: string;
    massAlertResetAfterEveryAlert: boolean;
    massAlertCollectFeedbacks: boolean;
    expressAlertTimeInSeconds: number;
    expressAlertSeparator: string;
    expressAlertDontWaitForText: boolean;
    expressAlertCancelIfTextNotAvailable: boolean;
    allowAllUsersToAccessAllUnits: boolean;
    shareAdminAddressbook: boolean;
    shareAdminRoles: boolean;
    shareImageAssets: boolean;
    sharePluginTemplates: boolean;
    enablePersonShareFromUserContext: boolean;
    deleteRoadblocks: boolean;
    enforceCustomerSmsKey: boolean;
    enableAutoUpdate: boolean;
    shareFeedbackForAlarmUpdates: boolean;
    ttsSpeed: number;
    voice?: Voice;
    allVoices: Voice[];
    wachalarmSource: string;
    mqttBroker: MQTTBroker;
    personDuplicateAlarmActive: boolean;
    personDuplicateAlarmTimeInHours: number;
    shareStatusDuringMission: boolean;
    shareObjectsOnAlarm: boolean;
    shareChatrooms: boolean;
}

export enum MQTTBroker {
    CLUSTER = 'CLUSTER', LEGACY = 'LEGACY'
}


export interface GeneralResponse {
    message: string;
}

export interface WebserverSettings {
    title: string;
    port: number;
    externalAddress: string;
    externalAddressAMweb: string;
    use2fa: boolean;
    useSsl: boolean;
    hostnameValidation: boolean;
    expiryDuration: number;
    restrictedIps: string;
    keystore: string;
    password: string;
    securityTxtContact: string;
}

export interface MasterSlaveSettings {
    mode: EServerMode;
    masterIp: string;
    masterPort: number;
    allowSelfSigned: boolean;
    interval: number;
    useGlobalProxy: boolean
}

export interface MailAccountSettings {
    server: string;
    username: string;
    sender: string;
    alias: string;
    password: string;
    auth: boolean;
    ssl: boolean;
    port: number;
    receiver: string[];
    smimeSigningPath: string;
    smimeSigningPassword: string;
    emailTemplateImgPath: string;
    provider: EProvider;
}

export interface OutputPluginSettings {
    first: Map<string, DeactivatedPluginListEntry>;
    second: Map<string, DeactivatedPluginListEntry>;
}

export interface DeactivatedPluginListEntry {
    className: string;
    deactivated: boolean;
}

export interface KeywordSettings {
    field: string;
    matchingActive: boolean;
    overwriteKeywordMode: OverwriteKeywordMode;
}

export enum OverwriteKeywordMode {
    NONE = 'NONE',
    OVERWRITE = 'OVERWRITE',
    ADD = 'ADD'
}

export interface ReplacementSettings {
    data: string;
    trimWhitespace: boolean;
}

export interface OnlineServiceMapping {
    id: number;
    idToDisplay: string;
    inUse: boolean;
    name: string;
}

export interface UpdateOnlineServiceMapping {
    userId: string;
    osId: number;
}

export interface OutageSettings {
    hasMailAccount: boolean;
    outageActive: boolean;
    dailyMailActive: boolean;
    warningActive: boolean;
    environmentSettings: boolean;
    dailyMailTime: string;
    outageId: string;
    outageName: string;
    outageSystems: OutageSystem[];
}

export interface OutageSystem {
    name: string;
    id: string;
}

export interface FeedbackUnitGroupSimple{
  name: string;
  id: string;
  delayTime: number;
  considerExternalId: boolean;
}
export interface FeedbackGroupsUnits {
  id: string;
  units: Unit[];
}

export interface AlarmSplitterConfig {
    splitters: SplitterData[],
    waitTime: number,
    postWaitTime: number
}

export interface SplitterData {
    codeFor: string,
    codeFrom: string,
    codeTo: string
}

export interface SimpleUnit {
    id: string,
    code: string,
    name: string,
    parentName: string
}

export interface AddressSettings {
    digitsCoordinate: number;
    longitudeBeforeLatitude: boolean;
    positionMode: number;
    notFoundMode: number;
    useRegardless: boolean;
    lookForBuildingsWithStreetOnly: boolean;
    ignoreIfBuildingWasFound: boolean;
    checkSurroundingObjects: boolean;
    checkSurroundingObjectsRadius: number;
    checkOnlyExactObjectName: boolean;
    findDirectionCardWithoutObject: boolean;
}

export interface AdminAlarmObjectsResponse{
   alarmObjects: AdminAlarmObject[];
  totalElements: number;
  totalPages: number;
  currentPage: number;
}
export interface AdminAlarmObject{
    abbreviation:string
    street:string;
    city:string;
    house:string;
    postalCode:string;
    additional:string;
    building:string;
    lat:number;
    lng:number;
}
export interface AdminStreetsResponse{
  streets:AdminStreet[];
  totalElements: number;
  totalPages: number;
  currentPage: number;

}

export interface AdminStreet{
  postalCode:string;
  abbreviation:string
  city:string
  street:string
}

export interface ComingHomeSettings {
    status97Mode: EStatus97Mode;
    comingHomeStatus1: boolean;
    comingHomeStatus2: boolean;
    comingHomeStatus3: boolean;
    comingHomeStatus4: boolean;
    comingHomeStatus5: boolean;
    comingHomeStatus6: boolean;
    comingHomeStatus7: boolean;
    comingHomeStatus8: boolean;
    comingHomeStatus9: boolean;
}

export enum EStatus97Mode {
    COMING_HOME = 'COMING_HOME',
    OPEN_GATE = 'OPEN_GATE'
}

export interface LicenceDistribution {
    licenceType: AdjustLicenceType;
    organisationLicences: UserLicenceResponse[];
}

export interface UserLicenceResponse {
    user: string;
    used: number;
    assigned: number;
}


export interface MqttStatus {
    connected: boolean;
    fromInputPlugin: boolean;
    broker: string;
    connectionName: string;
    key: string;
    hasFallback: boolean;
    fallbackConnected: boolean;
    fallbackBroker: string;
    deliveredMessages: number;
    deliveryFailedMessages: number;
    deliveredFallbackMessages: number;
    deliveryFailedFallbackMessages: number;
    receivedMessages: number;

    atLeastOnSuccessfullyConnect: boolean;
    atLeastOnSuccessfullyFallbackConnect: boolean;
}

export interface HttpMetrics {
    host: string;
    okCounter: number;
    failedCounter: number;
    state: HttpMetricState;
}

export enum HttpMetricState {
    OK = 'OK',
    ALL_FAILED = 'ALL_FAILED',
    RECENTLY_FAILED = 'RECENTLY_FAILED'
}

export interface ConnectionResponse {
    creationTime: String;
    lastChange: String;
    lastChangeSpan: String;
    source: EConnectionSource;
    sourceColor: string;
    hashId: number;
    shortId: String;
    name: String;
    connected: boolean;
    connectionInfo: string;
}

export enum EConnectionSource {
    GRCP = 'GRCP',
    TCP = 'TCP',
    COM = 'COM',
    WS = 'WS',
    HTTP = 'HTTP'
}