'use strict';

require('./system.status.modal.scss');


/* @ngInject */
export default class SystemStatusModalController {
  private $uibModalInstance;

  constructor($uibModalInstance) {
    this.$uibModalInstance = $uibModalInstance;
  }

  cancel() {
    this.$uibModalInstance.close();
  }

}