'use strict';

import angular = require("angular");
import {AlarmData} from "../data/alarm.data";
import {EAvailabilityState} from "../data/availability.data";
import {EFeedbackState} from "../data/customFeedback.data";
import * as L from 'leaflet';


/* @ngInject */
export default class HelperService {
  private localStorageService;
  private $translate;
  private sounds: string[] = [];

  constructor($translate, localStorageService) {
    this.localStorageService = localStorageService;
    this.$translate = $translate;
    this.sounds = ['A_A_A_A', 'A_A_BBB', 'AAABBBB', 'AB__AC', 'ABABABAB', 'AB-BA-AB', 'A-B-B-B', 'ABC_A_A', 'ABC_ABC', 'ABC_CAB', 'AC_C_A',
      'alarm', 'alarmton1', 'autoalarm-5s', 'b', 'B_B', 'B_B_A_B', 'beep', 'bong', 'C_______', 'C_C_B_B', 'CABCABCA', 'C-C-ABAB', 'C-C-C-C-', 'CGEC', 'click', 'DME2', 'DME3', 'DME4', 'feueralarm-5s', 'feueralarm-10s', 'feueralarm-30s',
      'happy', 'jingle', 'lautlos', 'Motorola BMD', 'notification', 'notification2', 'notification3', 'notify', 'nuklear-5s', 'pager', 'sound1', 'sound2', 'sound3', 'sound4', 'sound5', 'sound6', 'sound7', 'sound8', 'sQuad_1', 'sQuad_2', 'telesign',
      'Ton 1', 'Ton 2', 'Ton 3', 'Ton 3kurz', 'Ton 4', 'Ton 15', 'Ton 19', 'Ton 27', 'ton4_kurz', 'ton27_kurz', 'vollalarm',
      'wecker-5s', 'wecker-10s', 'wecker-30s', 'default'
    ];
  }

  getIconForPluginname(pluginClassName: string): string {
    let iconCss = 'icon-' + pluginClassName.replace(/.*\./, '');
    return iconCss;
  }

  /**
   * Returns true, if the given value is a valid aPager PRO token
   * @param value
   * @returns
   */
  isApagerProToken(value: string): boolean {
    if (!value) return false;
    return value.match(/[0123456789abcdefghijklmnopqrstuvwxyz]{8}/) !== null;
  }

  /**
   * Get all available sounds
   */
  getSounds() {
    return this.sounds;
  }

  /**
   * Generates a random password with at least 12 charactes
   * @param length desired length (min. 12)
   * @returns
   */
  generatePassword(length?: number) {

    const charLength = length && length >= 12 ? length : 12;

    const randomNumber = Array(3).fill("23456789").map((x) => {
      return x[Math.floor(Math.random() * x.length)]
    }).join('');

    const randomUppercase = Array(3).fill("ABCDEFGHJKLMNPQRSTUVWXYZ").map((x) => {
      return x[Math.floor(Math.random() * x.length)]
    }).join('');

    const randomLowercase = Array(3).fill("abcdefghjkmnopqrstuvwxyz").map((x) => {
      return x[Math.floor(Math.random() * x.length)]
    }).join('');

    const randomSpecialchars = Array(2).fill("-_").map((x) => {
      return x[Math.floor(Math.random() * x.length)]
    }).join('');


    const combinedChars = randomNumber + randomUppercase + randomLowercase + randomSpecialchars;

    const remainingCharsLength = charLength - combinedChars.length;
    let remainingChars = "";
    if (remainingCharsLength > 0) {
      remainingChars = Array(remainingCharsLength).fill("23456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz-_$%?!&@§=").map((x) => {
        return x[Math.floor(Math.random() * x.length)]
      }).join('');
    }

    const randomPassword = (combinedChars + remainingChars)
      .split('')
      .sort(() => {return 0.5 - Math.random()})
      .join('')

    return randomPassword;
  }
  correctNumberRange(value: number) {
    value = Math.abs(value);
    if (value > 2147483647) {
      value = 2147483647;
    }

    return value;
  }


  /**
   * Convert string fields to arrays
   */
  updatePersonFields(person) {
    //Groups
    if (angular.isDefined(person.groups) && person.groups !== '') {
      var groupNames = person.groups.replace(/,/, ';').split(';');
      person.groupsAsList = [];
      //Check for undefined or empty group names
      for (var i = 0; i < groupNames.length; i++) {
        var group = groupNames[i];
        if (angular.isUndefined(group) || group.trim() === '') {
          continue;
        }
        person.groupsAsList.push(group.trim());
      }
    } else {
      person.groupsAsList = [];
    }

    //Functions
    if (angular.isDefined(person.groups) && person.groups !== '') {
      var functions = person.functions.replace(/,/, ';').split(';');
      person.functionsAsList = [];
      //Check for undefined or empty functions
      for (var i = 0; i < functions.length; i++) {
        var func = functions[i];
        if (angular.isUndefined(func) || func.trim() === '') {
          continue;
        }
        person.functionsAsList.push(func.trim());
      }
    } else {
      person.functions = [];
    }
    return person;
  };

  /**
   * Get leaflet dash style pattern
   */
  getDashArray(pathStyle: string, weigth: number) {

    let gap = weigth * 2;
    switch (pathStyle) {
      case 'SOLID':
        return '';
      case 'DOTTED':
        return '1 ' + gap;
      case 'DASHED':
        return '16 ' + gap;
      case 'DOT_DASH':
        return '4 ' + gap + ' 16 ' + gap;
      default:
        return '';
    }
  }

  /**
   * Get icon for risk class
   * @param {*} type
   */
  getIconForRiskClass(type: string) {
    if (type === 'FLAMMABLE_GAS') {
      return 'flam_1';
    } else if (type === 'NON_FLAMMABLE_GAS') {
      return 'flam_2';
    } else if (type === 'FLAMMABLE_SOLID') {
      return 'flam_solid';
    } else if (type === 'PRESSURE_GAS') {
      return 'gas';
    } else if (type === 'EX_SOLID') {
      return 'exp_1';
    } else if (type === 'CORROSIV') {
      return 'acid';
    } else if (type === 'TOXIC') {
      return 'poison';
    } else if (type === 'DANGER') {
      return 'danger';
    } else if (type === 'TOXIC_BREATH') {
      return 'heart';
    } else if (type === 'TOXIC_ENVIRONMENT') {
      return 'fish';
    } else if (type === 'HEAT') {
      return 'heat';
    } else if (type === 'RADIOACTIVE_1') {
      return '7';
    } else if (type === 'RADIOACTIVE_2') {
      return '7-2';
    } else if (type === 'RADIOACTIVE_3') {
      return '7-3';
    } else if (type === 'INFECT') {
      return '6_infect';
    } else if (type === 'FLAMMABLE_LIQUID') {
      return 'liquid';
    } else if (type === 'LIQUID') {
      return 'liquid';
    } else if (type === 'DANGEROUS_WHEN_WET') {
      return 'dang_wet';
    } else if (type === 'SURFACE') {
      return 'top';
    } else {
      return undefined;
    }
  };

  /**
   * Get icon for roadblock type
   * @param {*} type
   */
  getIconForRoadblock(type: string) {
    if (type === 'ROAD_WORKS') {
      return 'icon-roadwork';
    } else if (type === 'ROAD_CLOSED') {
      return 'icon-roadclosed';
    } else {
      return 'icon-roadwarn';
    }
  };


  /**
   * Sort a group for availability
   */
  sortGroup(group) {
    var data = group.data;
    var tmpAvailable = [];
    var tmpNotAvailable = [];
    var tmpTempNotAvailable = [];

    for (var i = 0; i < data.length; i++) {
      var person = data[i];
      if (person.state === 'AVAILABLE') {
        tmpAvailable.push(person);
        person.stateLiteral = 1;
        continue;
      }
      if (person.state === 'NOT_AVAILABLE') {
        tmpNotAvailable.push(person);
        person.stateLiteral = 3;
        continue;
      }
      if (person.state === 'TEMP_NOT_AVAILABLE') {
        tmpTempNotAvailable.push(person);
        person.stateLiteral = 2;
        continue;
      }
      if (person.state === 'NONE') {
        tmpTempNotAvailable.push(person);
        person.stateLiteral = 4;
        continue;
      }
    }

    //Reset
    group.data = [];

    //Combine lists
    //Available
    for (var i = 0; i < tmpAvailable.length; i++) {
      group.data.push(tmpAvailable[i]);
    }
    //Tmp Not Available
    for (var i = 0; i < tmpTempNotAvailable.length; i++) {
      group.data.push(tmpTempNotAvailable[i]);
    }
    //Not Available
    for (var i = 0; i < tmpNotAvailable.length; i++) {
      group.data.push(tmpNotAvailable[i]);
    }
    return group;
  };

  /**
   * Add a person to all his groups
   */
  addPersonToGroup(person, defaultGroup, groups) {
    if (person.groupsAsList.length === 0) {
      //Has no group
      defaultGroup.data.push(person);
    }


    for (var cntGroup = 0; cntGroup < person.groupsAsList.length; cntGroup++) {
      var group = undefined;
      var groupName = person.groupsAsList[cntGroup];
      //Look for group with name
      for (var ii = 0; ii < groups.length; ii++) {
        if (groups[ii].name === groupName) {
          group = groups[ii];
          break;
        }
      }

      if (angular.isUndefined(group)) {
        //Group does not exist yet
        group = {
          data: [],
          name: groupName
        };
        groups.push(group);
      }
      //Add to group
      group.data.push(person);
    }
  };

  prepareResponse(onlineservice) {
    //Update persons
    for (var i = 0; i < onlineservice.lstOfAvailabilities.length; i++) {
      //Convert persons string fields to array
      this.updatePersonFields(onlineservice.lstOfAvailabilities[i]);
    }

    var sorting = function (a, b) {
      var a = a.toLowerCase(),
        b = b.toLowerCase();
      if (a < b) //sort string ascending
        return -1;
      if (a > b)
        return 1;
      return 0; //default return value (no sorting)
    };

    //Sort functions
    onlineservice.functions.sort(sorting);
    //Sort groups
    onlineservice.groups.sort(sorting);
    return onlineservice;
  };

  /**
   * Load a value from local storage
   */
  getFromStorage(key: string, defaultValue: any) {
    var loadedValue = this.localStorageService.get(key);
    if (angular.isUndefined(loadedValue) || loadedValue === null) {
      return defaultValue;
    }
    return loadedValue;
  }

  /**
   * Set a value from local storage
   */
  saveInStorage(key: string, value: any) {
    this.localStorageService.set(key, value);
  }

  /**
   * Get color for availability
   */
  getColorForAvailability(state: EAvailabilityState) {
    switch (state) {
      case EAvailabilityState.AVAILABLE:
        return '#5cb85c';
      case EAvailabilityState.NOT_AVAILABLE:
        return '#d9534f';
      case EAvailabilityState.TEMP_NOT_AVAILABLE:
        return '#FF6600';
      default:
        return '#04738C';
    }
  }

  getBackgroundColorForFeedback(state: EFeedbackState) {
    switch (state) {
      case EFeedbackState.YES:
        return '#5cb85c';
      case EFeedbackState.NO:
        return '#d9534f';
      case EFeedbackState.ABSENT:
        return '#d9534f';
      case EFeedbackState.FREE:
        return '#FF6600';
      default:
        return '#04738C';
    }
  }

  getIconClassForFeedback(feedback: EFeedbackState) {
    switch (feedback) {
      case EFeedbackState.YES:
        return 'fa-solid fa-circle-check';
      case EFeedbackState.NO:
        return 'fa-solid fa-circle-xmark';
      case EFeedbackState.ABSENT:
        return 'fa-solid fa-ban';
      case EFeedbackState.READ:
        return 'fa-eye fa-solid';
      case EFeedbackState.RECEIVED:
        return 'fa-solid fa-envelope';
      case EFeedbackState.FREE:
        return 'fa-solid fa-circle-info';
      case EFeedbackState.HERE:
        return 'fa-solid fa-location-check';
    }
  }

  humanReadableDateTime(timestamp: number) {
    if (timestamp === undefined) {
      return 'Unbekannt';
    }
    var now = (new Date()).getTime();
    var diff = now - timestamp;
    diff = diff / 1000;

    var sec = Math.round(diff);
    var min = Math.round(diff / 60);
    var hour = Math.round(min / 60);
    var days = Math.round(hour / 24);

    var before = this.$translate.instant('HUMANREADABLETIME.BEFORE')

    //Days
    if (days !== 0) {
      if (days === 1) {
        return before + this.$translate.instant('HUMANREADABLETIME.ONE_DAY');

      } else {
        if (days > 365) {
          return before + this.$translate.instant('HUMANREADABLETIME.ONE_YEAR');
        } else {
          return before + days + this.$translate.instant('HUMANREADABLETIME.DAYS');
        }
      }
    }

    //Hours
    if (hour !== 0) {
      if (hour === 1) {
        return before + this.$translate.instant('HUMANREADABLETIME.HOUR');
      } else {
        return before + hour + this.$translate.instant('HUMANREADABLETIME.HOURS');
      }
    }

    //Minutes
    if (min !== 0) {
      if (min === 1) {
        return before + this.$translate.instant('HUMANREADABLETIME.MINUTE');
      } else {
        return before + min + this.$translate.instant('HUMANREADABLETIME.MINUTES');
      }
    }

    //Seconds
    if (sec !== 0) {
      if (sec === 1) {
        return before + this.$translate.instant('HUMANREADABLETIME.SECOND');
      } else {
        return before + sec + this.$translate.instant('HUMANREADABLETIME.SECONDS');
      }
    }

    return (new Date(timestamp)).toLocaleTimeString();
  }

  getRoadblockState(roadblock) {
    var now = new Date();
    var from = new Date(roadblock.from);
    var to = new Date(roadblock.to);

    var status = '';
    if (to < now) {
      status = 'PASSED';
    } else if (from > now) {
      status = 'PLANNED';
    } else {
      status = 'ACTIVE';
    }
    return status;
  }

  /**
   Get the symbol for the specificied alarm. The symbol depends on the alarm type
 */
  getSymbolForAlarm(alarm: AlarmData) {
    if (angular.isUndefined(alarm)) {
      return '';
    }
    if (alarm.hasLiveImage) {
      return 'fa-video';
    }

    switch (alarm.alarmType) {
      case 'AVAILABILITY':
        return 'fa-users';
      case 'ALIVE':
        return 'fa-heartbeat';
      case 'CALENDAR':
        return 'fa-calendar';
      case 'ALARM':
        return 'fa-bell';
      case 'STATUS':
        return 'fa-truck';
      case 'ESPA':
        return 'fa-industry-alt';
      case 'EXTERNAL':
        return 'fa-globe';
      case 'FAX':
        return 'fa-fax';
      case 'FILE':
        return 'fa-file-alt';
      case 'DME':
        return 'fa-pager';
      case 'GRASLAND':
        return 'fa-fire';
      case 'HOCHWASSER':
        return 'fa-water';
      case 'REICHWEITE':
        return 'fa-broadcast-tower';
      case 'WALDBRAND':
        return 'fa-trees';
      case 'WETTER':
        return 'fa-thunderstorm';
      case 'XMPP':
        return 'fa-comment';
      case 'MAIL':
        return 'fa-envelope-open-text';
      case 'MANUAL':
        return 'fa-desktop';
      case 'INFO':
        return 'fa-comment-alt-lines';
      case 'IO':
        return 'fa-network-wired';
    }
    return 'fa-bell';
  };

  getContrastColor(color: string) {

      var brightness = this.calculateContrastColorBrightness(color);
      if (brightness < 0.5) {
        return '#282d31'; // bright colors - dark font
      }
      else {
        return '#ffffff'; // dark colors - white font
      }

  }

  /**
   * method calculation the brightness of a color and returns a numeric value
   * @param color background color
   * @returns calculated brightness, Will return 0.9 (dark contrast color) as default if calculation wasn't possible
   */
  calculateContrastColorBrightness(color: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    if (result) {
      var rgb = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }

      return 1 - (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
    }
    // return default dark color in getContrastColor
    return 0.9;
  }

  /**
   *
   * @param fontAwesomeIcon The font awesome icon (e.g. 'fas fa-fire')
   * @param emoji
   * @param color The background color (e.g. 'red')
   * @returns
   */
  createDivIconWithFontAwesomeIcon(fontAwesomeIcon: string, emoji: string, color: string): L.divIcon {
    if (!color) {
      color = 'red';
    }
    let html = ``;
    if (emoji && emoji !==''){
      html =`
    <div style='background-color:${color}' class='marker-pin'></div>
    <i class='awesome'>${emoji}</i>`;
    } else {
     html = `
    <div style='background-color:${color}' class='marker-pin'></div>
    <i class='${fontAwesomeIcon} awesome custom-div-icon-siren'></i>`;
    }

    return L.divIcon({
        className: 'custom-div-icon',
        iconSize: [30, 42],
        iconAnchor: [15, 42],
        html: html
      } as L.DivIconOptions);
  }

  valueIsNotMail(value: string): boolean {
    return !value?.includes('@');
  }
}
