'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { IOBattery, IOBatteryMeasurement } from "../../../../data/iobattery.data";
import { Vehicle, VoltageState } from "../../../../data/vehicles.data";
import RestService from "../../../../services/rest.service";

require('./vehicle.voltage.component.scss')

export default class VehicleVoltageComponent {

    public restrict: string;
    public scope: any;
    public template: any;
    public controller: any;
    public controllerAs: string;
    public bindToController: boolean;

    constructor() {
        this.restrict = 'EA'
        this.scope = {
            vehicle: '='
        }
        this.template = require('./vehicle.voltage.component.html');

        this.controller = VehicleVoltageComponentController;
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }

}


class VehicleVoltageComponentController {

    private translations = {
        voltage: "Spannung",
        min: "Untergrenze",
        max: "Obergrenze",
        noSelection: "Keine Auswahl"
    }

    public vehicle: Vehicle;
    public batteryMonitors: IOBattery[] = [];
    public selectedBmName: string = "Keine Auswahl";
    public chart: any = {
        data: [],
        options: {}
    };
    public startTime: Date = new Date();
    public endTime: Date = new Date();
    public hasGraph: boolean = false;

    constructor(private $scope: IScope,
        private $rootScope: IRootScopeService,
        private $log: ILogService,
        private restService: RestService,
        private $translate: any) {

        this.startTime.setDate(this.endTime.getDate() - 2);
        this.startTime.setSeconds(0);
        this.startTime.setMilliseconds(0);
        this.endTime.setSeconds(0);
        this.endTime.setMilliseconds(0);

        this.loadBatteryMonitors()
            .then(() => this.loadMeasurements());

        $translate("VEHICLES.VOLTAGE.CHART.VOLTAGE")
            .then(translation => this.translations.voltage = translation);
        $translate("VEHICLES.VOLTAGE.CHART.MIN")
            .then(translation => this.translations.min = translation);
        $translate("VEHICLES.VOLTAGE.CHART.MAX")
            .then(translation => this.translations.max = translation);
        $translate("VEHICLES.VOLTAGE.NO_SELECTION")
            .then(translation => this.translations.noSelection = translation)
        this.$scope.$applyAsync();
    }

    private loadBatteryMonitors(): Promise<void> {
        return this.restService.getIOBatteriesAsUser()
            .then(BMs => {
                this.batteryMonitors = BMs;
            })
            .finally(() => {
                this.updateSelected();
            });
    }

    private loadMeasurements(): void {
        if(!this.vehicle.connectedBatteryMonitorId) {
            return;
        }
        this.restService.loadBatteryMeasurements(this.vehicle.connectedBatteryMonitorId, this.startTime, this.endTime)
            .then(measurements => this.configureChart(measurements))
            .finally(this.$scope.$applyAsync);
    }

    private configureChart(measurements: IOBatteryMeasurement[]) {
        if(measurements.length == 0) {
            this.hasGraph = false;
            return;
        }
        this.hasGraph = true;
        let datapoints = measurements.map(mes => {
            return {
                x: mes.timestamp,
                y: mes.voltage
            }
        })
        let series = [
            this.translations.voltage,
            this.translations.min,
            this.translations.max
        ]
        let minThresh = this.generateThreshLine(datapoints, this.vehicle.minVoltage);
        let maxThresh = this.generateThreshLine(datapoints, this.vehicle.maxVoltage);
        let threshOverride = {
            borderColor: "red",
            fill: false,
            pointRadius: 0,
            pointHoverRadius: 0,
            cubicInterpolationMode: 'monotone'
        };

        let datasetOverrides = [
            {
                borderColor: "black",
                fill: false
            },
            threshOverride,
            threshOverride
        ]

        this.chart = {
            data: [datapoints, minThresh, maxThresh],
            series: series,
            datasetOverrides: datasetOverrides
        }
        this.chart.options = {
            elements: {
                line: {
                    tension: 0
                },
                point: {
                    radius: 0
                }
            },
            tooltips: {
                callbacks: {
                    title: this.tooltipTitle,
                    mode: "point"
                }
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            autoSkip: true,
                            maxRotation: 60,
                            minRotation: 0,
                            maxTicksLimit: 5
                        },
                        type: 'time',
                        time: {
                            displayFormats: {
                                millisecond: "DD.MM.YYYY hh:mm",
                                second: "DD.MM.YYYY hh:mm",
                                minute: "DD.MM.YYYY hh:mm",
                                hour: "DD.MM.YYYY hh:mm"
                            },
                            min: this.startTime.getTime(),
                            max: this.endTime.getTime()
                        }
                    }
                ],
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: "Spannung in Volt"
                        },
                        ticks: {
                            beginAtZero: false
                        }
                    }
                ]
            }
        }
    }

    private generateThreshLine(data: any, threshVoltage: number) {
        let count = Math.max(1, data.length - 1);
        let points = [
            {
                x: this.startTime.getTime(),
                y: threshVoltage
            }
        ];
        for (let i = 0; i < count; i++) {
            points.push({
                x: this.endTime.getTime(),
                y: threshVoltage
            })
        }
        return points;
    }

    public updateSelected() {
        let found = this.batteryMonitors.filter(bm => bm.dataStoreId === this.vehicle.connectedBatteryMonitorId);
        if (found.length < 1) {
            this.selectedBmName = this.translations.noSelection;
            this.$scope.$applyAsync();
            return;
        }
        this.selectedBmName = found[0].deviceId;
        this.$scope.$applyAsync();
    }

    public selectNone() {
        this.vehicle.connectedBatteryMonitorId = null;
        this.updateSelected();
    }

    public select(bm: IOBattery) {
        this.vehicle.connectedBatteryMonitorId = bm.dataStoreId;
        this.updateSelected();
    }

    private tooltipTitle(tooltipItem, chart) {
        let timestamp = tooltipItem[0].xLabel;
        return new Date(timestamp).toLocaleString("de-DE",
            {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                hourCycle: "h24",
                minute: "2-digit",
                second: "2-digit"
            });
    }

    public updateChart() {
        this.loadMeasurements();
    }

    public onStartTimeChanged() {
        if (this.startTime > this.endTime) {
            this.endTime = new Date(this.startTime.getTime() + 1 * 60 * 60 * 1000);// Add one hour
        }
        this.loadMeasurements();
    }

    public isVoltageGood(): boolean {
        return this.vehicle.batteryStatus.currentVoltageState === VoltageState.GOOD;
    }

}
