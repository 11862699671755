'use strict';

require('./charts.component.css');

export default class AvailabilityChartsComponent {
  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '='
      },
      this.template = require('./charts.component.html');

    this.controller = AvailabilityChartsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityCharts
/* @ngInject */
class AvailabilityChartsController {
  constructor($scope, $rootScope, $log, helperService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.helperService = helperService;

    this.statistic = {};
    this.functions = {};
    this.groups = {};
    this.groupsAsArray = [];

    this.$scope.$watch('ctrl.onlineservice', (newValue, oldValue) => {
      if (angular.isDefined(newValue)) {
        //Watch for changes
        this.clearOldData();
        this.calculateStatistics();
      }
    });
  }

  clearOldData(){
    this.statistic = {};
    this.functions = {};
    this.groups = {};
    this.groupsAsArray = [];
  }
  /**
   * Calculate statistics for summary
   */
  calculateStatistics() {
    this.$log.debug('Calculating statistics...');
    var available = 0;
    var notAvailable = 0;
    var tempNotAvailable = 0;
    var none = 0;

    for (var i = 0; i < this.onlineservice.data.lstOfAvailabilities.length; i++) {
      var person = this.onlineservice.data.lstOfAvailabilities[i];

      //Sum up functions
      if (person.functionsAsList.length !== 0 && person.state === 'AVAILABLE') {
        for (var iFunc = 0; iFunc < person.functionsAsList.length; iFunc++) {
          var func = person.functionsAsList[iFunc];
          var cnt = this.functions[func];
          if (cnt === undefined) {
            this.functions[func] = 1;
          } else {
            this.functions[func]++;
          }
        }
      }
      //Sum up groups
      if (person.groupsAsList.length !== 0 && person.state === 'AVAILABLE') {
        for (var iGr = 0; iGr < person.groupsAsList.length; iGr++) {
          var group = person.groupsAsList[iGr];
          var cnt = this.groups[group];
          if (cnt === undefined) {
            this.groups[group] = 1;
          } else {
            this.groups[group]++;
          }
        }
      }


      //Sum up functions from all persons who are available
      if (person.state === 'AVAILABLE') {
        available++;
        continue;
      }
      if (person.state === 'NOT_AVAILABLE') {
        notAvailable++;
        continue;
      }
      if (person.state === 'TEMP_NOT_AVAILABLE') {
        tempNotAvailable++;
        continue;
      }
      if (person.state === 'NONE') {
        none++;
        continue;
      }
    }

    //Sum up statistic
    this.statistic.available = available;
    this.statistic.notAvailable = notAvailable;
    this.statistic.tempNotAvailable = tempNotAvailable;
    this.statistic.none = none;
    this.statistic.total = available + notAvailable + tempNotAvailable + none;

    angular.forEach(this.groups, (val, key) => {
      this.groupsAsArray.push({ name: key, cnt: val });
    });

    this.createChartData();
  };

  createChartData() {
    //Create chart data
    //Gesamt
    this.labelsAll = [];
    this.dataAll = [];
    if (this.statistic.available != 0) {
      this.labelsAll.push('Verfügbar');
      this.dataAll.push(this.statistic.available);
    }
    if (this.statistic.tempNotAvailable != 0) {
      this.labelsAll.push('Temp. nicht Verfügbar');
      this.dataAll.push(this.statistic.tempNotAvailable);
    }
    if (this.statistic.notAvailable != 0) {
      this.labelsAll.push('Nicht Verfügbar');
      this.dataAll.push(this.statistic.notAvailable);
    }
    if (this.statistic.none != 0) {
      this.labelsAll.push('Unbekannt');
      this.dataAll.push(this.statistic.none);
    }

    this.chartColors = { colors: ['#3c763d', '#8a6d3b', '#a94442', '#337ab7'] }

    //Functions
    this.labelsFunctions = [];
    this.dataFunctions = [];
    var tmpDataFunctions = [];
    for (var key in this.functions) {
      var obj = this.functions[key];
      // important check that this is objects own property
      // not from prototype prop inherited
      if (this.functions.hasOwnProperty(key)) {
        tmpDataFunctions.push({
          func: key,
          cnt: obj
        });
      }
    }
    //Sort functions
    tmpDataFunctions.sort((a, b) => {
      return a.cnt > b.cnt
    });

    for (var i = 0; i < tmpDataFunctions.length; i++) {
      this.labelsFunctions.push(tmpDataFunctions[i].func);
      this.dataFunctions.push(tmpDataFunctions[i].cnt);
    }
  };
}
