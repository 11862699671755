import angular from 'angular';
import 'angular-ui-bootstrap'

import VehicleTableRowComponent from './vehicle.tablerow.component/vehicle.tablerow.component'
import VehicleFaultComponent from './vehiclefault.component/vehiclefault.component'
import VehicleListComponent from './vehicle.list.component/vehicle.list.component'
import VehicleOverviewComponent from './vehicle.overview.component/vehicle.overview.component'

import services from '../../../services'
import StatusComponent from './status.component/status.component';
import ParkingPositionComponent from './parking.position.component/parking.position.component';
import ParkingPositionAdminComponent from "./parking.position.admin.component/parking.position.admin.component"
import TimeplanComponent from './timeplan.component/timeplan.component';
import RelaisLabelComponent from './relais.label.component/relais.label.component';
import DashboardComponent from './dashboard.component/dashboard.component';

import WidgetComponent from './widgets/widget.component/widget.component';
import CategoryComponent from './widgets/category.component/category.component';
import CustomFieldComponent from './custom.field.component/custom.field.component';
import CategoryManagementComponent from './category.management.component/category.management.component';
import HistoryComponent from './history.component/history.component';
import StatusTableauComponent from './tableau.component/tableau.component';
import MetaComponent from './meta.component/meta.component';
import AnnouncementComponent from "./announcement.component/announcement.component";
import AAOComponent from "./aao.component/aao.component";
import AAOEditorComponentController from "./aao.component/aao.editor/aao.editor";
import AAODayComponentController from "./aao.component/aao.editor/aao.day/aao.day";
import VehicleVoltageComponent from "./vehicle.voltage.component/vehicle.voltage.component";
import GateAdminComponent from "./gate.admin.component/gate.admin.component";
import VehicleLocationComponent from "./vehicle.location.component/vehicle.location.component";
import WacheMapComponent from "./wache.map.component/wache.map.component";
import VehicleRescueServicesComponent from "./vehicle.rescue-services.component/vehicle.rescue-services.component";
import VehicleTrackingSettingsComponent from "./vehicle.trackingSettings.component/vehicle.trackingSettings.component";
import VehicleDevicesComponent from "./vehicle.devices.component/vehicle.devices.component";
import DevicesComponent from "./devices.component/devices.component";

export default angular.module('FE2.components.wache', ['ui.bootstrap', services])
  //.controller('MainController', MainController)
  .directive('vehicleTableRow', () => new VehicleTableRowComponent)
  .directive('vehicleListEntry', () => new VehicleListComponent)
  .directive('status', () => new StatusComponent)
  .directive('parkingTableRow', () => new ParkingPositionComponent)
  .directive('parkingTableRowAdmin', () => new ParkingPositionAdminComponent)
  .directive('gates', () => new GateAdminComponent)
  .directive('timeplan', () => new TimeplanComponent)
  .directive('relaisLabel', () => new RelaisLabelComponent)
  .directive('dashboard', () => new DashboardComponent)
  .directive('vehiclefault', () => new VehicleFaultComponent)
  .directive('vehicleOverview', () => new VehicleOverviewComponent)
  .directive('vehicleVoltage', () => new VehicleVoltageComponent)
  .directive('vehicleLocation', () => new VehicleLocationComponent)
  .directive('widget', () => new WidgetComponent)
  .directive('categoryWidget', () => new CategoryComponent)
  .directive('customField', () => new CustomFieldComponent)
  .directive('categoryManagement', () => new CategoryManagementComponent)
  .directive('statusHistory', () => new HistoryComponent)
  .directive('statusTableau', () => new StatusTableauComponent)
  .directive('metaInformation', () => new MetaComponent)
  .directive('announcement', () => new AnnouncementComponent)
  .directive('vehicleAao', () => new AAOComponent)
  .directive('vehicleAaoEditor', () => new AAOEditorComponentController)
  .directive('aaoDay', () => new AAODayComponentController)
  .directive('wacheMap', () => new WacheMapComponent)
  .directive('vehicleRescueServices', () => new VehicleRescueServicesComponent)
  .directive('vehicleTrackingSettings', () => new VehicleTrackingSettingsComponent)
  .directive('vehicleDevices', ()=> new VehicleDevicesComponent)
  .directive('devices', () => new DevicesComponent)
  .name;
