'use strict';

import {IScope} from "angular";
import * as L from "leaflet";
import {VehicleLocationResponse} from "../../../../data/vehicles.data";


/* @ngInject */
export default class ChooseAlarmOnMapController {
  public $uibModalInstance: any;
  public $scope: IScope;
  public mapService: any;
  public dataService: any;
  public coords: MapCoords;
  public okFunction: any;

  public marker: L.Marker;
  public map;

  public isStatic = false;

  private positionOpt?: VehicleLocationResponse;

  constructor($uibModalInstance, $scope: IScope, $document, mapService, dataService, coords: MapCoords, position: VehicleLocationResponse, okFunction) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope = $scope;
    this.mapService = mapService;
    this.dataService = dataService;
    this.coords = coords;
    this.okFunction = okFunction;
    this.positionOpt = position;
    const settings = this.dataService.getAccount().settings;

    if (!this.coords.lat || !this.coords.lng) {
      this.coords = {
        lat: settings.lat,
        lng: settings.lng
      } as MapCoords;
    } else {
      // If accuracy is present, this is a static map
      this.isStatic = coords.accuracy !== undefined;
    }

    $document.ready(() => {
      this.initMap();
    });
  }

  /**
   * Init the map
   */
  initMap() {
    L.Icon.Default.imagePath = '/img/static';
    this.map = L.map('mapid').setView([this.coords.lat, this.coords.lng], 13);

    this.map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');

    let layers = this.mapService.getBaseLayers();

    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if(selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    let mapService = this.mapService
    this.map.on('baselayerchange', function(e) {
      mapService.saveLayer(e.name);
    });

    const latLng = L.latLng(this.coords.lat, this.coords.lng);

    if (this.coords.accuracy && this.coords.accuracy > 0) {
      const circleobj = L.circle(latLng, this.coords.accuracy);
      circleobj.addTo(this.map);
    }

    this.marker = L.marker(latLng, { draggable: !this.isStatic }).addTo(this.map);

    if (!this.isStatic) {
      this.map.on('click', (ev) => {
        this.markerMovedEvent(ev.latlng);
      });
      this.marker.on('dragend', (e) => {
        this.markerMovedEvent(e.target.getLatLng());
      });
    }

    if (this.positionOpt) {
      this.createVehicleMarker(this.positionOpt);
    }
  }

  /**
   * Should be triggered, if marker should be moved
   * @param latLng
   */
  markerMovedEvent(latLng: L.LatLng) {
    this.marker.setLatLng(latLng);
    this.coords.lat = latLng.lat;
    this.coords.lng = latLng.lng;
    this.$scope.$applyAsync();
  }

  /**
   * Return the new lat/lng and close modal
   */
  ok() {
    this.$uibModalInstance.close();
    const currentLatLng = this.marker.getLatLng();
    this.okFunction({
      lat: currentLatLng.lat,
      lng: currentLatLng.lng
    });
  }

  private createVehicleMarker(vehicle: VehicleLocationResponse) {
    const icon = this.buildVehicleMarkerIcon(vehicle);
    const position = [vehicle.lat, vehicle.lng];
    const marker = L.marker(position, { icon, title: vehicle.name });
    marker.addTo(this.map);
  }

  private buildVehicleMarkerIcon(vehicle: VehicleLocationResponse) {
    return L.divIcon({
      className: 'tracking-marker',
      html: this.buildVehicleMarkerHtml(vehicle),
      popupAnchor: [0, -25]
    });
  }

  private buildVehicleMarkerHtml(vehicle: VehicleLocationResponse) {
    return `<div class="tracking-icon-content" style="background: ${vehicle.statusColor};color: ${vehicle.statusTextColor};">` +
      `<div class="tracking-icon-content-status">${vehicle.statusValue}</div>` +
      '<div class="tracking-icon-content-data">' +
      '<div class="tracking-icon-content-data-name">' + vehicle.name + '</div>' +
      '</div>' +
      '</div>';
  }
}

export interface MapCoords {
  lat: number,
  lng: number,
  accuracy: number
}