import { ILogService, IRootScopeService, IScope } from 'angular';
import CalendarService from '../../../../services/calendar.service';

import { Calendar, DatesSetArg, EventClickArg, EventDropArg, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import deLocale from '@fullcalendar/core/locales/de';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { CalendarSimple, ECalendarType } from '../../../../data/calendar.data';
import { EEventState, EEventType, Event, EventAccess } from '../../../../data/event.data';
import PrivilegeService from '../../../../services/privilege.service';
import { RolePrivilege } from '../../../../data/privileges.enum';
import HelperService from "../../../../services/helper.service";

'use strict';

require('./calendar.component.css');

export default class CalendarComponent {
  public restrict: string;
  public scope: any;
  public template: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E',
      this.scope = {
      }
    this.template = require('./calendar.component.html');
    this.controller = CalendarController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class CalendarController {
  public $uibModal;
  public $scope: IScope;
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public calendarService: CalendarService;
  public isLoading: boolean;
  public showDropdown: boolean = false;
  public dataService: any;
  // Data
  public calendars: CalendarSimple[] = [];
  public info: DateClickArg;

  public calendar: Calendar;
  public events: EventAccess[] = [];

  public listeners = [];

  // Current selection
  public month: number;
  public year: number;
  public surrounding: boolean;
  public $state: any;

  constructor($uibModal, $scope: IScope, $rootScope: IRootScopeService, $state, $log: ILogService, calendarService: CalendarService, public privilegeService: PrivilegeService, dataService, public helperService: HelperService) {
    this.$uibModal = $uibModal;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$state = $state;
    this.calendarService = calendarService;
    this.dataService = dataService;

    // Load calendars
    this.listeners.push(this.$rootScope.$on('calendars.loaded', (event, calendars: CalendarSimple[]) => {
      this.$log.info('calendars.loaded: New calendars available!');
      this.calendars = this.filterCalendars(calendars);
    }));

    // Calendar has been deleted, update events
    this.listeners.push(this.$rootScope.$on('calendar.deleted', (event) => {
      this.$log.info('calendar.deleted: A calendar has been deleted, update events');
      this.reloadEvents().then(() => this.$scope.$apply());
    }));

    // Update of events needed
    this.listeners.push(this.$rootScope.$on('events.updated', () => {
      this.$log.info('events.updated: Need to update events!');
      this.reloadEvents().then(() => this.$scope.$apply());
    }));

    this.listeners.push(this.$rootScope.$on('new.account', () => {
      //Init controller
      this.init();
    }));

    if (this.dataService.hasAccount()) {
      this.init();
    }

    $scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  filterCalendars(calendars: CalendarSimple[]): CalendarSimple[] {
    return calendars.filter(calendar => !calendar.icalSyncCalendar);
  }

  init() {
    if (!this.privilegeService.has(RolePrivilege.EventPlanning_Appointment)) {
      this.$state.go('main.' + this.dataService.selectPageForPrivilege());
      return;
    }
    this.initCalendar();
    this.loadCalendars();
  }

  loadCalendars() {
    this.isLoading = true;
    this.calendarService.getCalendars().then(response => {
      this.calendars = this.filterCalendars(response);
      this.isLoading = false;
    }).catch(error => {
      this.$log.error('Error getting simple calendars:', error);
      this.isLoading = false;
    });
  }

  initCalendar() {
    var calendarEl = document.getElementById('calendar');

    this.calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, listPlugin, interactionPlugin],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,listWeek'
      },
      weekNumbers: true,
      weekText: '',
      navLinks: true, // can click day/week names to navigate views
      editable: true,
      dayMaxEventRows: true,
      moreLinkClick: 'popover',
      locale: deLocale,
      dayMaxEvents: true, // allow "more" link when too many events
      dateClick: (info) => this.dateClicked(info),
      eventClick: (info) => this.eventClicked(info),
      eventDrop: (info) => this.eventMoved(info),
      datesSet: (info) => this.viewChanged(info)
    });

    this.calendar.render();
  }

  reloadEvents(): Promise<EventAccess[]> {
    return this.loadEvents(this.month, this.year, this.surrounding)
  }

  /**
   * Load all upcoming events
   */
  loadEvents(month: number, year: number, surrounding: boolean): Promise<EventAccess[]> {
    return new Promise<EventAccess[]>((resolve, reject) => {
      this.$log.info('Loading events for ' + month + '/' + year);
      this.calendarService.getEventsForUserInMonth(month, year, surrounding).then((responseData: EventAccess[]) => {
        this.events = responseData;
        this.addEventsToCalendar();
        this.month = month;
        this.year = year;
        this.surrounding = surrounding;
        resolve(responseData);
      }).catch(error => {
        this.$log.error(error);
        reject(error);
      });
    });

  }

  addEventsToCalendar() {
    this.calendar.removeAllEvents();
    this.events.forEach(eventWithAccess => {

      const event: Event = eventWithAccess.event;
      var brightness;
      var textColor = 'event-title-bright-background div';
      if (event.fullDay){
        brightness = this.helperService.calculateContrastColorBrightness(event.color);
        if (brightness >= 0.5) {
          textColor=  'event-title-dark-background div';
        }
      }



      const eventInput = {
        title: event.title,
        allDay: event.fullDay,
        start: new Date(event.startDate),
        end: new Date(event.endDate),
        backgroundColor: event.color,
        borderColor: event.color,
        className:  textColor,
        editable: this.privilegeService.has(RolePrivilege.EventPlanning_Appointment_Edit),
        id: event.id,
        extendedProps: {
          location: event.location,
          responsiblePerson: event.responsiblePerson
        }
      } as EventInput;

      switch (event.status) {
        case EEventState.COMPLETED:
          eventInput.className = ['past-event', textColor];
          break;
        case EEventState.CANCELLED:
          eventInput.className = [textColor, 'cancelled-event'];
          break;

      }

      this.calendar.addEvent(eventInput);
    });
  }

  /**
   * Open the calendar select dropdown
   * @param info
   */
  dateClicked(info: DateClickArg) {
    if (!this.privilegeService.has(RolePrivilege.EventPlanning_Appointment_Create)) {
      return;
    }
    this.info = info;

    let posX = info.jsEvent.clientX;
    let posY = info.jsEvent.clientY;

    const menu = document.getElementById('calChooserContainer');
    const calendar = document.getElementById('calendar');

    menu.style.left = posX - calendar.getBoundingClientRect().x + 'px';
    menu.style.top = posY - calendar.getBoundingClientRect().y + 'px';

    this.showDropdown = true;
    this.$scope.$apply();
  }

  /**
   * Event has been clicked
   * @param info The clicked event
   */
  eventClicked(info: EventClickArg) {
    if (!this.privilegeService.has(RolePrivilege.EventPlanning_Appointment_Edit)) {
      return;
    }

    const event = this.events.filter(event => event.event.id === info.event.id)[0];
    this.openEventModal(event, false);
  }


  /**
   * Will be triggered if event has been moved
   * @param info
   */
  eventMoved(info: EventDropArg) {
    const event = this.events.filter(event => event.event.id === info.event.id)[0];
    event.event.startDate = this.calendarService.dateToIsoString(info.event.start);
    event.event.endDate = this.calendarService.dateToIsoString(info.event.end);

    this.isLoading = true;
    this.calendarService.saveEvent(event, false, false).then(() => {
      this.$log.info('Event dropped and changes saved!');
      this.isLoading = false;
    }).catch(error => {
      this.$log.error('Event could not changed', error);
      this.isLoading = false;
      info.revert()
    });
  }

  /**
   * Will be triggered, if user changes month
   * @param info
   */
  viewChanged(info: DatesSetArg) {
    const startMonth = info.start.getMonth();
    const endMonth = info.end.getMonth();

    let newYear = info.start.getFullYear();
    let newMonth = 0;
    if (startMonth + 1 === endMonth) {
      // Only time period of two months
      newMonth = startMonth;
    } else {
      // Time period of three months. Choose the middle one
      newMonth = startMonth + 1;
      if (newMonth === 12) {
        // New year started
        newMonth = 0;
        newYear = newYear + 1;
      }
    }

    newMonth = newMonth + 1;// because in java script, months start with 0, we add +1
    this.loadEvents(newMonth, newYear, true);
  }


  /**
   * Create a new calendar
   * @param calendar
   */
  createEvent(calendar: CalendarSimple) {
    let type = EEventType.EVENT;
    switch (calendar.type) {
      case ECalendarType.COURSE:
        type = EEventType.COURSE
        break;
      case ECalendarType.LESSON:
        type = EEventType.LESSON;
        break;
      case ECalendarType.TRAINING:
        type = EEventType.TRAINING
        break;
    }
    this.calendarService.getEventTemplateForCalendar(calendar.id, type, this.info).then((responseData: EventAccess) => {
      this.openEventModal(responseData, true);
    }).catch(error => {
      this.$log.error(error);
    });
  }

  /**
   * Open the event modal
   * @param event The event to open
   * @param isNew If this event was newly created
   */
  openEventModal(event: EventAccess, isNew: boolean) {
    this.$uibModal.open({
      template: require('../../../modals/calendar/event.modal/event.modal.html'),
      controller: 'EventModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        event: () => {
          return event;
        },
        calendars: () => {
          return this.calendars;
        },
        isNew: () => {
          return isNew;
        },
        okFunction: () => {
          return () => {
            this.reloadEvents();
            return;
          };
        }
      }
    });
  }
}